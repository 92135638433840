@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Mooli&display=swap');
*{
  margin: 0;
  padding: 0;
}

.mooli-regular {
  font-family: "Mooli", sans-serif;
  font-weight: 400;
  font-style: normal;
}